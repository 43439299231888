const currencyFormat = (priceString: number, withPrefixCurrency = true) => {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: withPrefixCurrency ? 'currency' : undefined,
    currency: withPrefixCurrency ? 'IDR' : undefined,
    maximumFractionDigits: 0,
  })
  return formatter.format(priceString).replace(/\s/g, '') /* $2,500.00 */
}

export default currencyFormat
