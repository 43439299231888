import { createSessionStore } from 'store/user'
import { getUAFromCookie } from 'utils/helpers/getUAFromCookie'

import { BRANCH_ENABLE } from 'config/constants'

const isClient = () => typeof window !== 'undefined'

let instance: BranchType

export const getBranchInstance = () => instance

export const initBranch: typeof instance.init = (...args) => {
  if (!isClient() || !BRANCH_ENABLE || instance) {
    return
  }

  import('branch-sdk').then((m) => {
    const branchModule = m.default

    branchModule.init(...args)

    instance = branchModule
  })
}

const withBranchChecking = (fn: (branch: BranchType) => void) => {
  if (!isClient() || !instance) {
    return
  }

  fn(instance)
}

export const logEvent: BranchType['logEvent'] = (
  event,
  eventDataAndCustomData,
  contentItems,
  customerEventAlias,
  callback,
) =>
  withBranchChecking((branch) => {
    const { user, customerAddress } = createSessionStore().getState()

    const { device, os } = getUAFromCookie()
    const vp = device?.type === 'mobile' ? 'mobile' : 'desktop'
    const parsedOS = os.name?.toLocaleLowerCase()

    const mergedData: Record<string, unknown> = {
      userId: `${user?.id || ''}`,
      locationId: customerAddress?.location_id || undefined,
      deviceType: `web ${vp}`,
      deviceOs: parsedOS,
      ...eventDataAndCustomData,
    }

    // Remove undefined keys
    Object.keys(mergedData).forEach(
      (key) => mergedData[key] === undefined && delete mergedData[key],
    )

    branch.logEvent(event, mergedData, contentItems, customerEventAlias, callback)
  })

export const setIdentity: BranchType['setIdentity'] = (...args) =>
  withBranchChecking((branch) => branch.setIdentity(...args))

export const data: BranchType['data'] = (...args) =>
  withBranchChecking((branch) => branch.data(...args))

export const first: BranchType['first'] = (...args) =>
  withBranchChecking((branch) => branch.first(...args))

export const logout: BranchType['logout'] = (...args) =>
  withBranchChecking((branch) => branch.logout(...args))

export const getBrowserFingerprintId: BranchType['getBrowserFingerprintId'] = (...args) =>
  withBranchChecking((branch) => branch.getBrowserFingerprintId(...args))
