import { type Callback } from 'mixpanel-browser'
import {
  getScreenNameMetaFromDocument,
  trackEvents,
  type TrackEventsPropertiesType,
} from 'lib/mixpanelTracker'

import { getUAFromCookie } from 'utils/helpers/getUAFromCookie'
import { type GetBannerContentType } from 'utils/apiList/lite/banner'
import { mixpanelEventName, mixpanelEventCategory } from './mixpanelConstants'

/**
 * Event Action List reference: https://docs.google.com/spreadsheets/d/1VvswDcQXlgQdq7Q-DAomLK5cQpGOGTs60y9fRHS6yYE/edit?gid=276386993#gid=276386993
 */

/**
 * No. 309
 */
export const trackClickButtonDownloadApps = (
  properties?: TrackEventsPropertiesType,
  callback?: Callback,
) => {
  const screenName = getScreenNameMetaFromDocument()
  const { os } = getUAFromCookie()
  const parsedOS = os.name?.toLocaleLowerCase()
  const normalizeOS = parsedOS === 'ios' || parsedOS === 'android' ? parsedOS : 'desktop'

  const mapEventLabel = {
    ios: 'appstore',
    android: 'playstore',
    desktop: '',
  }

  trackEvents(
    mixpanelEventName.CLICK_COMPONENT,
    {
      eventCategory: screenName,
      eventLabel: mapEventLabel[normalizeOS],
      ...properties,
    },
    callback,
  )
}

export const trackAppMonitoring = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.APP_MONITORING, {
    screenName,
    eventCategory: screenName,
    ...properties,
  })
}

export const trackViewComponent = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.VIEW_COMPONENT, {
    screenName,
    eventCategory: screenName,
    ...properties,
  })
}

/*
  track each product card event.
  ex: click product, view product, add to cart etc.
*/
export const trackEachProduct = (eventAction: string, properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  const eventData = {
    eventCategory: screenName,
    screenName,
    eventLabel: '',
    currentSite: '',
    item_list: '',
    items: [],
    ...properties,
  }

  switch (screenName) {
    case mixpanelEventCategory.CATALOGUE_PAGE: {
      eventData.item_list = `/${mixpanelEventCategory.CATALOGUE_PAGE} - product list`
      break
    }
    case mixpanelEventCategory.CATEGORY_PAGE: {
      eventData.item_list = `/${mixpanelEventCategory.CATEGORY_PAGE} - product list`
      break
    }
    case mixpanelEventCategory.HOME_PAGE: {
      eventData.item_list = `/${mixpanelEventCategory.HOME_PAGE} - product list`
      break
    }
    case mixpanelEventCategory.RECOMMENDATION_PAGE: {
      eventData.item_list = `/${mixpanelEventCategory.RECOMMENDATION_PAGE} - product list -  recommendation ${eventData.eventLabel}`
      break
    }
    default:
      break
  }

  trackEvents(eventAction, eventData)
}

/*
  track each banner image action
  ex: view image, click image
*/
export const trackEachBannerImage = ({
  eventAction,
  item,
  index,
  properties,
}: {
  eventAction: 'click banner' | 'view banner'
  item: GetBannerContentType
  index: number
  properties?: TrackEventsPropertiesType
}) => {
  const screenName = getScreenNameMetaFromDocument()

  const eventData = {
    screenName,
    eventCategory: screenName,
    eventLabel: item.banner_name,
    eventAction,
    items: [
      {
        item_id: item.banner_id,
        item_name: `/${screenName} - banner`,
        creative_name: item.banner_name,
        creative_slot: index,
      },
    ],
    ...properties,
  }

  trackEvents(
    eventAction === 'click banner'
      ? mixpanelEventName.SELECT_PROMOTION
      : mixpanelEventName.VIEW_PROMOTION,
    eventData,
  )
}

export const trackClickButtonReorder = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()
  trackEvents(mixpanelEventName.ADD_TO_CART, {
    eventCategory: screenName,
    eventLabel: '',
    ...properties,
  })
}
export const trackClickButtonSaveAddress = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.INPUT_ADDRESS, {
    eventCategory: screenName,
    eventLabel: '',
    ...properties,
  })
}

export const trackSearchProduct = (
  productResultTotal: number,
  productRelatedTotal: number,
  searchType: string | null,
  properties?: TrackEventsPropertiesType,
) => {
  trackEvents(mixpanelEventName.SEARCH, {
    eventCategory: mixpanelEventCategory.TOP_NAVIGATION,
    search_type: searchType || ``,
    count_product_result: String(productResultTotal || 0),
    count_product_recom_result: String(productRelatedTotal),
    count_product_crosssell_result: '0',
    count_product_total: String(productResultTotal + productRelatedTotal),
    keyword_suggestion: ``,
    redirection_mode: 'none',
    ...properties,
  })
}

export const trackAddressConfirmationCorrect = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.ECOMMERCE_PURCHASE, {
    eventCategory: screenName,
    eventAction: 'click ya alamat sudah tepat - after select bayar - on alamatnya sudah tepat',
    eventLabel: '',
    currentSite: '',
    screenName,
    ...properties,
  })
}

export const trackAddressConfirmationChanged = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.CLICK_BUTTON, {
    eventCategory: screenName,
    eventAction: 'click ganti alamat - after select bayar - on alamatnya sudah tepat',
    eventLabel: '',
    currentSite: '',
    screenName,
    ...properties,
  })
}

export const trackClickPaymentMethodThereisVoucher = (
  voucherId: string,
  properties?: TrackEventsPropertiesType,
) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.ECOMMERCE_PURCHASE, {
    eventCategory: screenName,
    eventAction:
      'click purchase - after select payment method - on ada promo yang tidak bisa digunakan',
    eventLabel: voucherId,
    currentSite: '',
    screenName,
    ...properties,
  })
}

export const trackClickPaymentAfterDelayedPopUp = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.ECOMMERCE_PURCHASE, {
    eventCategory: screenName,
    eventAction: 'click purchase - after select payment method - on pesanan mungkin tertunda',
    eventLabel: ``,
    currentSite: '',
    screenName,
    ...properties,
  })
}

export const trackSelectPaymentMethod = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.BEGIN_CHECKOUT, {
    eventCategory: screenName,
    eventAction: 'select payment method - before click purchase',
    eventLabel: '',
    currentSite: '',
    screenName,
    ...properties,
  })
}

export const trackSelectPaymentMethodAfterClickPay = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.ECOMMERCE_PURCHASE, {
    eventCategory: screenName,
    eventAction: 'select payment method - after click purchase',
    eventLabel: '',
    currentSite: '',
    screenName,
    ...properties,
  })
}

export const trackClickAddToCart = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.ADD_TO_CART, {
    eventCategory: screenName,
    eventAction: 'click add to cart on cart',
    eventLabel: '',
    currentSite: '',
    screenName,
    ...properties,
  })
}

export const trackClickPayBeforeSelectPayment = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.ADD_TO_CART, {
    eventCategory: screenName,
    eventAction: 'click purchase - before select payment method',
    eventLabel: '',
    currentSite: '',
    screenName,
    ...properties,
  })
}

export const trackClickPayAfterSelectPayment = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.ADD_TO_CART, {
    eventCategory: screenName,
    eventAction: 'click purchase - after select payment method',
    eventLabel: '',
    currentSite: '',
    screenName,
    ...properties,
  })
}

export const trackPickLocation = (properties?: TrackEventsPropertiesType) => {
  trackEvents(mixpanelEventName.CLICK_COMPONENT, {
    eventCategory: mixpanelEventCategory.PICK_LOCATION,
    screenName: mixpanelEventCategory.PICK_LOCATION,
    eventAction: '',
    eventLabel: '',
    currentSite: '',
    ...properties,
  })
}

export const trackClickAddToCartFromPDP = (properties?: TrackEventsPropertiesType) => {
  const screenName = getScreenNameMetaFromDocument()

  trackEvents(mixpanelEventName.ADD_TO_CART, {
    eventCategory: screenName,
    eventAction: 'click add to cart on pdp',
    eventLabel: '',
    currentSite: '',
    screenName,
    ...properties,
  })
}
