import { cache } from 'react'
import { QueryClient } from '@tanstack/react-query'
import { queryDefaultOptions } from './config'

/**
 * cache() is scoped per request, so we don't leak data between requests
 * "getQueryClient" mainly use on server component. For client component, please uses "useQueryClient"
 */
const getQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        ...queryDefaultOptions,
      },
    },
  })

export default cache(getQueryClient)
