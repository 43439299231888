import { trackEvents } from 'lib/mixpanelTracker'

type GetBUOProductDataType = (additionalProps: Record<string, unknown>) => Record<string, unknown>
/**
 *
 * @param product
 * @param additionalProps
 * @returns BUO (Branch Universal Object) data
 */
export const getBUOProductData: GetBUOProductDataType = (additionalProps = {}) => ({
  $og_title: '',
  $product_variant: '',
  $locally_indexable: false,
  $address_postal_code: '',
  $longitude: 0,
  $rating_average: 0,
  $product_brand: '',
  $canonical_identifier: '',
  $address_country: '',
  $latitude: 0,
  $product_name: '',
  $creation_timestamp: 0,
  $exp_date: 0,
  $address_city: '',
  $address_street: '',
  $publicly_indexable: true,
  $address_region: '',
  $og_description: '',
  $quantity: 0,
  $rating_max: 0,
  $price: 0,
  $sku: '',
  $og_image_url: '',
  $rating: 0,
  $canonical_url: '',
  $rating_count: 0,
  product_category: 'product',
  $currency: 'IDR',
  ...additionalProps,
})

export const getOGMetaAddToCart = (args: { source: string; action: 'add' | 'remove' }) => {
  const { source, action } = args

  const getMeta = (pageName: string) => ({
    $og_title: `${action === 'add' ? 'Add to' : 'Remove'} cart from ${pageName} page`,
    og_description: `${action === 'add' ? 'Add to' : 'Remove'} cart from ${pageName} page`,
  })

  switch (source) {
    case 'pdp': {
      return getMeta('product')
    }
    case 'search': {
      return getMeta('search result')
    }
    // 'cart' | 'dynamicChannel' | 'category' | 'catalogue'
    default: {
      return getMeta(source)
    }
  }
}

export const trackEventToMixpanel: typeof trackEvents = (event, properties, callback) => {
  trackEvents(
    `[Branch] ${event}`,
    {
      isLoggedInStatus: undefined,
      ...properties,
    },
    callback,
  )
}
