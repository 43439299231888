import {
  QueryClientProvider,
  HydrationBoundary,
  dehydrate as reactQueryDehydrate,
  QueryClient,
  useQuery,
  useInfiniteQuery,
  InfiniteData,
  useMutation,
  useQueryClient,
  UseQueryResult,
  useQueries,
  type QueryFunctionContext,
  type UseQueryOptions,
} from '@tanstack/react-query'

import ReactQueryProvider from './ReactQueryProvider'
import getQueryClient from './getQueryClient'

const dehydrate: typeof reactQueryDehydrate = (
  client,
  options = { shouldDehydrateQuery: () => false },
) => reactQueryDehydrate(client, options)

export type UseDeferredQueryType<T = unknown> = UseQueryOptions<T>

const useDeferredQuery = <TData>(opts: UseDeferredQueryType<TData>) =>
  useQuery({
    ...opts,
    enabled: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    retry: false,
  })

export {
  ReactQueryProvider,
  getQueryClient,
  QueryClientProvider,
  HydrationBoundary,
  dehydrate,
  QueryClient,
  reactQueryDehydrate,
  useQuery,
  useInfiniteQuery,
  type InfiniteData,
  useMutation,
  useDeferredQuery,
  useQueryClient,
  type UseQueryResult,
  useQueries,
  type QueryFunctionContext,
}
